import NASPairABI from '../abis/NASPairABI.json';
import NASRouterABI from '../abis/NASRouterABI.json';
import NASFactoryABI from '../abis/NASFactoryABI.json';
import NASERC20ABI from '../abis/NASERC20ABI.json';

export const NAS_FACTORY_ADDRESS = '0xB955e714D476d263ceDA04f4ca824Ea31B7D0287'; 
export const NAS_ROUTER_ADDRESS = '0x112c05f30eA69Cb55d8c71878eEDe1F6c0B846eC'; 
export const NAS_TOKEN_ADDRESS = '0xE75FC1097a7b4fd226f3b644d893e12E10F88b7C'; 
export const USDT_TOKEN_ADDRESS = '0xc2132d05d31c914a87c6611c10748aeb04b58e8f'; 
export const NAS_PAIR_ADDRESS = '0x5d6084Bf7a8049Fd3db89a7af9e7291002F36Ac7'; 

export { NASPairABI, NASRouterABI, NASFactoryABI, NASERC20ABI };