import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';
import { formatUnits } from 'ethers';
import { EthersContext } from '../EthersProvider';
import { NASFactoryABI, NASRouterABI, NASPairABI, NAS_FACTORY_ADDRESS, NAS_ROUTER_ADDRESS, NAS_TOKEN_ADDRESS, USDT_TOKEN_ADDRESS } from '../utils/contracts';
import backgroundImage from '../images/dex_background.png';
import NasImage from '../images/nas_token_27.png';
import UsdtImage from '../images/usdt_27.png';
import './DEX.css';

const DEX = () => {
  const { provider, signer } = useContext(EthersContext);
  const [fromToken, setFromToken] = useState('NAS');
  const [toToken, setToToken] = useState('USDT');
  const [amount, setAmount] = useState('');
  const [estimatedAmount, setEstimatedAmount] = useState('0');
  const [slippage, setSlippage] = useState(0.5);
  const [gasEstimate, setGasEstimate] = useState('0');
  const [balances, setBalances] = useState({ NAS: '0', USDT: '0' });
  const [isLoading, setIsLoading] = useState(false);
  const [recentTxs, setRecentTxs] = useState([]);
  const [prices, setPrices] = useState({ NAS: '0', Na: '0', USDT: '1' });
  const [maxSwapAmount, setMaxSwapAmount] = useState('10000');
  const [showFee, setShowFee] = useState(false);
  const [nasFactoryContract, setNasFactoryContract] = useState(null);
  const [nasRouterContract, setNasRouterContract] = useState(null);
  const [pairAddress, setPairAddress] = useState(null);
  const [minAmountOut, setMinAmountOut] = useState('0');
  /* eslint-disable */
  const initContracts = useCallback(async () => {
    if (!signer) {
      console.log('Signer not available');
      return;
    }
    
    try {
      console.log('Initializing contracts...');
      const factory = new ethers.Contract(NAS_FACTORY_ADDRESS, NASFactoryABI, signer);
      const router = new ethers.Contract(NAS_ROUTER_ADDRESS, NASRouterABI, signer);
      setNasFactoryContract(factory);
      setNasRouterContract(router);
  
      const pairAddress = await factory.getPair(NAS_TOKEN_ADDRESS, USDT_TOKEN_ADDRESS);
      setPairAddress(pairAddress);
  
      console.log('Contracts initialized:', { 
        factory: factory.address, 
        router: router.address, 
        pair: pairAddress 
      });
    } catch (error) {
      console.error('Error initializing contracts:', error);
    }
  }, [signer]);

  const updateBalances = useCallback(async () => {
    const nasToken = new ethers.Contract(NAS_TOKEN_ADDRESS, NASPairABI, signer);
    const usdtToken = new ethers.Contract(USDT_TOKEN_ADDRESS, NASPairABI, signer);
    const address = await signer.getAddress();

    const nasBalance = await nasToken.balanceOf(address);
    const usdtBalance = await usdtToken.balanceOf(address);

    setBalances({
      NAS: formatUnits(nasBalance, 18),
      USDT: formatUnits(usdtBalance, 6)
    });
  }, [signer]);

  const updatePrices = useCallback(async () => {
    try {
      console.log('Updating prices...');
      setPrices({
        NAS: '0.01',
        Na: '0.001',
        USDT: '1'
      });
      console.log('Prices updated:', { NAS: '0.01', Na: '0.001', USDT: '1' });
    } catch (error) {
      console.error('가격 정보 업데이트 실패:', error);
    }
  }, []);

  useEffect(() => {
    updatePrices();
    const intervalId = setInterval(updatePrices, 30000);
    return () => clearInterval(intervalId);
  }, [updatePrices]);

  const estimateSwap = useCallback(async () => {
    if (!nasRouterContract || !amount || parseFloat(amount) <= 0) return;
  
    try {
      console.log('Estimating swap...');
      const amountIn = ethers.parseUnits(amount, fromToken === 'NAS' ? 18 : 6);
      const path = [
        fromToken === 'NAS' ? NAS_TOKEN_ADDRESS : USDT_TOKEN_ADDRESS,
        toToken === 'NAS' ? NAS_TOKEN_ADDRESS : USDT_TOKEN_ADDRESS
      ];
  
      const amounts = await nasRouterContract.getAmountsOut(amountIn, path);
      const estimatedAmountOut = amounts[1];
  
      setEstimatedAmount(ethers.formatUnits(estimatedAmountOut, toToken === 'NAS' ? 18 : 6));
  
      // 슬리피지 적용
      const slippageTolerance = 1 - slippage / 100;
      const minAmountOut = estimatedAmountOut * BigInt(Math.floor(slippageTolerance * 10000)) / 10000n;
  
      setMinAmountOut(ethers.formatUnits(minAmountOut, toToken === 'NAS' ? 18 : 6));
  
      console.log('Swap estimated:', { 
        estimatedAmount: ethers.formatUnits(estimatedAmountOut, toToken === 'NAS' ? 18 : 6),
        minAmountOut: ethers.formatUnits(minAmountOut, toToken === 'NAS' ? 18 : 6)
      });
    } catch (error) {
      console.error('거래 금액 계산 중 오류가 발생했습니다:', error);
      setEstimatedAmount('0');
      setMinAmountOut('0');
    }
  }, [nasRouterContract, amount, fromToken, toToken, slippage]);

  const fetchRecentTxs = useCallback(async () => {
    if (!nasFactoryContract || !pairAddress) return;

    try {
      const pair = new ethers.Contract(pairAddress, NASPairABI, provider);
      const filter = pair.filters.Swap();
      const blockNumber = await provider.getBlockNumber();
      const events = await pair.queryFilter(filter, blockNumber - 100, blockNumber);

      const transactions = await Promise.all(events.map(async (event) => {
        const block = await event.getBlock();
        const tokenInAddress = event.args.amount0In > 0 ? await pair.token0() : await pair.token1();
        const tokenOutAddress = event.args.amount0Out > 0 ? await pair.token0() : await pair.token1();
        return {
          txHash: event.transactionHash,
          timestamp: block.timestamp,
          tokenIn: tokenInAddress === NAS_TOKEN_ADDRESS ? 'NAS' : 'USDT',
          tokenOut: tokenOutAddress === NAS_TOKEN_ADDRESS ? 'NAS' : 'USDT',
          amountIn: ethers.formatUnits(event.args.amount0In > 0 ? event.args.amount0In : event.args.amount1In, tokenInAddress === NAS_TOKEN_ADDRESS ? 18 : 6),
          amountOut: ethers.formatUnits(event.args.amount0Out > 0 ? event.args.amount0Out : event.args.amount1Out, tokenOutAddress === NAS_TOKEN_ADDRESS ? 18 : 6)
        };
      }));

      setRecentTxs(transactions);
    } catch (error) {
      console.error('최근 거래 내역 조회 중 오류 발생:', error);
      alert('최근 거래 내역을 가져오는 데 실패했습니다.');
    }
  }, [nasFactoryContract, pairAddress, provider]);

  const fetchMaxSwapAmount = useCallback(() => {
    setMaxSwapAmount('10000');
  }, []);

  useEffect(() => {
    let isSubscribed = true;
  
    const fetchData = async () => {
      if (signer && isSubscribed) {
        await initContracts();
        await updateBalances();
        await fetchRecentTxs();
        fetchMaxSwapAmount();
      }
    };
  
    if (signer) {
      console.log('Signer available, fetching data...');
      fetchData();
    } else {
      console.log('Signer not available');
    }
   
    return () => {
      isSubscribed = false;
    };
  }, [signer]);
  
  useEffect(() => {
    const intervalId = setInterval(updatePrices, 30000);
    return () => clearInterval(intervalId);
  }, [updatePrices]);

  useEffect(() => {
    if (amount && amount !== '0') {
      estimateSwap();
    } else {
      setEstimatedAmount('0');
      setGasEstimate('0');
      setMinAmountOut('0'); 
    }
  }, [fromToken, toToken, amount, estimateSwap]);

  const handleSwap = async () => {
    if (!nasRouterContract) {
      alert('지갑을 연결해주세요.');
      return;
    }
    if (!nasRouterContract || !nasFactoryContract) {
      alert('컨트랙트가 초기화되지 않았습니다. 지갑을 연결해주세요.');
      return;
    }
    setIsLoading(true);
    setShowFee(true);
    try {
      console.log('Starting swap...');
      const amountIn = ethers.parseUnits(amount, fromToken === 'NAS' ? 18 : 6);
      const tokenIn = fromToken === 'NAS' ? NAS_TOKEN_ADDRESS : USDT_TOKEN_ADDRESS;
      const tokenOut = toToken === 'NAS' ? NAS_TOKEN_ADDRESS : USDT_TOKEN_ADDRESS;

      // 수정: USDT 승인을 페어 컨트랙트에 대해 수행
      const pairAddress = await nasFactoryContract.getPair(NAS_TOKEN_ADDRESS, USDT_TOKEN_ADDRESS);
      console.log('Pair address:', pairAddress);
  
      // 수수료 계산 (0.5% USDT)
      let feeInUsdt;
      if (fromToken === 'USDT') {
        feeInUsdt = amountIn * 5n / 1000n;
      } else {
        try {
      // NAS to USDT 비율을 컨트랙트에서 가져오기
      const pairContract = new ethers.Contract(
        pairAddress,
        ['function getReserves() public view returns (uint112 _reserve0, uint112 _reserve1, uint32 _blockTimestampLast)'],
        signer
      );
      const [reserve0, reserve1] = await pairContract.getReserves();
      const amountInUsdt = amountIn * reserve1 / reserve0;
      feeInUsdt = amountInUsdt * 5n / 1000n;
    } catch (error) {
      console.error('Failed to get pair reserves:', error);
      // fallback to fixed ratio
      const amountInUsdt = amountIn * ethers.parseUnits('0.01', 6) / ethers.parseUnits('1', 18);
      feeInUsdt = amountInUsdt * 5n / 1000n;
    }
    }
  
      console.log('Amount In:', ethers.formatUnits(amountIn, fromToken === 'NAS' ? 18 : 6));
      console.log('Estimated Fee (USDT):', ethers.formatUnits(feeInUsdt, 6));

      // USDT 컨트랙트 인스턴스 생성
      const usdtContract = new ethers.Contract(
        USDT_TOKEN_ADDRESS, 
        ['function approve(address spender, uint256 amount) public returns (bool)'], 
        signer
      );

      // 수수료에 대한 USDT 승인 (페어 컨트랙트에 대해)
      console.log('Approving USDT fee for pair contract...');
      const usdtFeeTx = await usdtContract.approve(pairAddress, feeInUsdt);
      await usdtFeeTx.wait();
      console.log('USDT fee approved for pair contract');
      
      // 입력 토큰에 대한 승인 (라우터에 대해)
      if (fromToken === 'USDT') {
        console.log('Approving USDT for router...');
        const usdtSwapTx = await usdtContract.approve(NAS_ROUTER_ADDRESS, amountIn);
        await usdtSwapTx.wait();
        console.log('USDT approved for router');
      } else {
        const tokenContract = new ethers.Contract(
          NAS_TOKEN_ADDRESS, 
          ['function approve(address spender, uint256 amount) public returns (bool)'], 
          signer
        );
        console.log('Approving NAS for router...');
        const approvalTx = await tokenContract.approve(NAS_ROUTER_ADDRESS, amountIn);
        await approvalTx.wait();
        console.log('NAS approved for router');
      }
  
      const path = [tokenIn, tokenOut];
      const to = await signer.getAddress();
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
  
      const slippageTolerance = ethers.parseUnits(slippage.toString(), 4);
      const amounts = await nasRouterContract.getAmountsOut(amountIn, path);
      const minAmountOutValue = amounts[1] * (10000n - slippageTolerance) / 10000n;

      console.log('Contract addresses:', {
        router: NAS_ROUTER_ADDRESS,
        pair: pairAddress,
        USDT: USDT_TOKEN_ADDRESS,
        NAS: NAS_TOKEN_ADDRESS
      });
  
      console.log('Swap parameters:', {
        amountIn: ethers.formatUnits(amountIn, fromToken === 'NAS' ? 18 : 6),
        amountOutMin: ethers.formatUnits(minAmountOutValue, toToken === 'NAS' ? 18 : 6),
        path: path.map(p => p.toLowerCase()),
        to,
        deadline
      });
  
      console.log('Executing swap...');
      const swapTx = await nasRouterContract.swapExactTokensForTokens(
        amountIn,
        minAmountOutValue,
        path,
        to,
        deadline,
        { 
          gasLimit: 1000000,
          gasPrice: ethers.parseUnits('200', 'gwei') // 현재 네트워크 상황에 맞게 조정
        }
      );
  
      console.log('Swap transaction sent:', swapTx.hash);
      const receipt = await swapTx.wait();
      console.log('Swap completed. Transaction receipt:', receipt);
  
      // 결과 로깅 (이전과 동일)
  
      alert('거래가 성공적으로 완료되었습니다.');
      updateBalances();
      fetchRecentTxs();
    } catch (error) {
      console.error('거래 중 오류가 발생했습니다:', error);
      if (error.reason) {
        console.error('Error reason:', error.reason);
      }
      if (error.data) {
        console.error('Error data:', error.data);
      }
      alert('거래에 실패했습니다. 다시 시도해 주세요.');
    } finally {
      setIsLoading(false);
      setTimeout(() => setShowFee(false), 3000);
    }
  };

  const handleSwitchTokens = () => {
    setFromToken(toToken);
    setToToken(fromToken);
    setAmount('');
    setEstimatedAmount('0');
  };

  const TokenSelect = ({ value, onChange, options }) => (
    <div className="token-select">
      <img 
        src={value === 'NAS' ? NasImage : UsdtImage} 
        alt={value} 
        className={`token-icon ${value === 'NAS' ? 'nas-icon' : ''}`} 
      />
      <select value={value} onChange={onChange}>
        {options.map(option => (
          <option key={option} value={option}>
            {option} {option === 'USDT' && '(폴리곤)'}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="dex">
      <div className="back-image">
        <img className="dexback-img" alt="Dexback" src={backgroundImage} />
      </div>
      <div className="content-wrapper">
        <div className="dex-swap">Swap 스왑</div>
        <h2>탈중앙화 거래소</h2>
        <div className="fast-transfer">빠르게 토큰과 코인을 거래할 수 있는 곳</div>
        <div className="dex-box">
          <div className="info-container">
            <div className="price-info">
              <p>NAS 가격: {prices.NAS} USDT</p>
              <p>USDT 가격: {prices.USDT} 달러</p>
              <p>Na 가격: {prices.Na} USDT</p>
            </div>
            <div className="balance-info">
              <p>NAS 잔액: {balances.NAS}</p>
              <p>USDT 잔액: {balances.USDT}</p>
            </div>
          </div>
          <div className="swap-form">
            <TokenSelect
              value={fromToken}
              onChange={(e) => setFromToken(e.target.value)}
              options={['NAS', 'USDT']}
            />
            <div className="amount-switch-container">
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="수량"
                className="amount-input"
              />
              <button onClick={handleSwitchTokens} className="switch-button">토큰 변경</button>
            </div>
            <TokenSelect
              value={toToken}
              onChange={(e) => setToToken(e.target.value)}
              options={['NAS', 'USDT']}
            />
            <div className="estimated-amount">
              <p>예상 거래 금액: {estimatedAmount}</p>
              <p>최소 받는 금액: {minAmountOut}</p>
            </div>
            <div className="gas-slippage">
              <div className="gas-estimate">
                <label>예상 가스비:</label>
                <span>{gasEstimate} Gwei</span>
              </div>
              <div className="slippage-input">
                <label htmlFor="slippage">슬리피지 (%):</label>
                <input
                  id="slippage"
                  type="number"
                  value={slippage}
                  onChange={(e) => setSlippage(Number(e.target.value))}
                  placeholder="슬리피지 %"
                />
              </div>
            </div>
            <p className="max-swap-amount">최대 거래 금액: {parseFloat(maxSwapAmount).toFixed(2)} USDT</p>
            <button onClick={handleSwap} disabled={isLoading} className="swap-button">
              {isLoading ? '거래 진행 중...' : '거래하기'}
            </button>
            {showFee && <p className="fee-info">수수료 0.5% USDT</p>}
          </div>
        </div>
        <div className="recent-transactions">
          <h3>✔︎ 최근 거래 내역</h3>
          <div className="transactions-box">
            <ul>
              {recentTxs.map((tx, index) => (
                <li key={index}>
                  <span className="tx-hash">거래 해시: {tx.txHash.slice(0, 10)}...</span>
                  <span className="tx-tokens">{tx.tokenIn === NAS_TOKEN_ADDRESS ? 'NAS' : 'USDT'} → {tx.tokenOut === NAS_TOKEN_ADDRESS ? 'NAS' : 'USDT'}</span>
                  <span className="tx-amount">금액: {tx.amountIn} → {tx.amountOut}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Link to="/" className="main-link">홈 화면으로 돌아가기</Link>
    </div>
  );
};

export default DEX;