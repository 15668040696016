import React from 'react';
import { Link } from 'react-router-dom';
import referralBackImage from '../images/referral_back.png';
import '../pages/Referral.css';
  
  const Referral = () => (
    <div className="referral">
      <div className="back-image">
        <img className="referral-back-img" alt="referral back" src={referralBackImage} />
      </div>
      <div className="content-wrapper">
        <div className="referral-intro">Referral 레퍼럴</div>
        <h2>추천 보너스</h2>
        <div className="friend">친구에게 <span className="highlight">Do! at NAS</span>를 소개하고<br />
         토큰 보상을 받으세요!</div>
        <div className="strong-reward">강력한 추천 리워드와 함께 멋진 탐험을 해보세요.</div>
      </div>  
        <Link to="/" className="main-link">홈 화면으로 돌아가기</Link>
    </div>
  );

export default Referral;