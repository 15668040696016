import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';
import { formatUnits, parseUnits } from 'ethers';
import { connectWallet, getSigner } from '../utils/walletUtils';
import { NASPairABI, NASFactoryABI, NASRouterABI, NASERC20ABI, NAS_FACTORY_ADDRESS, NAS_ROUTER_ADDRESS, NAS_PAIR_ADDRESS, NAS_TOKEN_ADDRESS, USDT_TOKEN_ADDRESS } from '../utils/contracts';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import aquaHeaderImage from '../images/liquidity-header.png';
import nasTokenImage from '../images/nas_token_27.png';
import usdtTokenImage from '../images/usdt_27.png';
import checkImage from '../images/check.png';
import '../pages/Aquarium.css';

function formatNumber(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Aquarium = React.memo(() => {
  const [nasAmount, setNasAmount] = useState('');
  const [usdtAmount, setUsdtAmount] = useState('');
  const [setPrice] = useState(0);
  const [balances, setBalances] = useState({ nasBalance: 0, usdtBalance: 0 });
  const [signer, setSigner] = useState(null);
  const [totalLiquidity, setTotalLiquidity] = useState('0');
  const [reserves, setReserves] = useState({ nas: '0', usdt: '0' });
  const [liquidityHistory, setLiquidityHistory] = useState({ added: [], removed: [] });
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '가격 변동 (USDT)',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        fill: false,
      },
      {
        label: '유동성 규모 (NAS)',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        fill: false,
      },
      {
        label: '거래량 (NAS)',
        data: [],
        borderColor: 'rgb(54, 162, 235)',
        fill: false,
      },
    ],
  });
  const [lockRemainingTime, setLockRemainingTime] = useState(0);
  const [estimatedLpTokens, setEstimatedLpTokens] = useState('0');
  const [minLiquidityAmount, setMinLiquidityAmount] = useState('100');
  const [nasRatio, setNasRatio] = useState(10000);
  const [usdtRatio, setUsdtRatio] = useState(100);
  const [setPotentialEarnings] = useState('0');
  const [nasFactoryContract, setNasFactoryContract] = useState(null);
  const [nasRouterContract, setNasRouterContract] = useState(null);

  const updateBalances = useCallback(async () => {
    if (signer) {
      const nasBalance = await new ethers.Contract(NAS_TOKEN_ADDRESS, NASERC20ABI, signer).balanceOf(await signer.getAddress());
      const usdtBalance = await new ethers.Contract(USDT_TOKEN_ADDRESS, NASERC20ABI, signer).balanceOf(await signer.getAddress());
      setBalances({
        nasBalance: formatUnits(nasBalance, 18),
        usdtBalance: formatUnits(usdtBalance, 6),
      });
    }
  }, [signer]);
  /* eslint-disable */
  const initAndFetchContractData = useCallback(async () => {
    try {
      console.log("데이터 가져오기 시작");
      const connected = await connectWallet();
      if (!connected) {
        console.log("지갑이 연결되지 않았습니다.");
        alert("지갑 연결에 실패했습니다. 다시 시도해주세요.");
        return;
      }
      const signer = getSigner();
      if (!signer) {
        console.log("signer를 가져오는데 실패했습니다.");
        return;
      }
      setSigner(signer);
  
      const factory = new ethers.Contract(NAS_FACTORY_ADDRESS, NASFactoryABI, signer);
      const router = new ethers.Contract(NAS_ROUTER_ADDRESS, NASRouterABI, signer);
      setNasFactoryContract(factory);
      setNasRouterContract(router);
  
      const pair = new ethers.Contract(NAS_PAIR_ADDRESS, NASPairABI, signer);
      
      const fixedPriceEnabled = await pair.fixedPriceEnabled();
      let currentPrice;
      const reserves = await pair.getReserves();
      const token0 = await pair.token0();
      const nasReserve = token0 === NAS_TOKEN_ADDRESS ? reserves[0] : reserves[1];
      const usdtReserve = token0 === USDT_TOKEN_ADDRESS ? reserves[0] : reserves[1];
  
      if (fixedPriceEnabled) {
        const fixedPrice = await pair.fixedPrice();
        currentPrice = fixedPrice ? formatUnits(fixedPrice, 18) : '0';
      } else {
        currentPrice = nasReserve && usdtReserve 
          ? formatUnits(BigInt(usdtReserve.toString()) * BigInt(10**18) / BigInt(nasReserve.toString()), 6) 
          : '0';
      }
      setPrice(parseFloat(currentPrice).toFixed(6));
  
      // NAS와 USDT 비율 계산
      const nasRatioValue = 10000; // NAS 비율
      const usdtRatioValue = 100;  // USDT 비율
      setNasRatio(nasRatioValue);
      setUsdtRatio(usdtRatioValue);
  
      setMinLiquidityAmount('100');
  
      const totalSupply = await pair.totalSupply();
      setTotalLiquidity(totalSupply ? formatUnits(totalSupply, 18) : '0');
  
      setReserves({
        nas: nasReserve ? formatUnits(nasReserve, 18) : '0',
        usdt: usdtReserve ? formatUnits(usdtReserve, 6) : '0'
      });
  
      const addedEvents = await pair.queryFilter(pair.filters.Mint);
      const removedEvents = await pair.queryFilter(pair.filters.Burn);
      setLiquidityHistory({ added: addedEvents, removed: removedEvents });
  
      setChartData((prevState) => ({
        ...prevState,
        labels: [...prevState.labels, new Date().toLocaleTimeString()],
        datasets: [
          { ...prevState.datasets[0], data: [...prevState.datasets[0].data, parseFloat(currentPrice)] },
          { ...prevState.datasets[1], data: [...prevState.datasets[1].data, totalSupply ? parseFloat(formatUnits(totalSupply, 18)) : 0] },
          { ...prevState.datasets[2], data: [...prevState.datasets[2].data, nasReserve ? parseFloat(formatUnits(nasReserve, 18)) : 0] },
        ],
      }));
  
      await updateBalances();
      console.log("데이터 가져오기 완료");
    } catch (error) {
      console.error("컨트랙트 초기화 및 데이터 가져오기 중 오류 발생:", error);
    }
  }, []);

   useEffect(() => {
     updateBalances();
   }, [signer, updateBalances]); 

  useEffect(() => {
    initAndFetchContractData();
    const interval = setInterval(initAndFetchContractData, 30000); // 30초마다 데이터 갱신
    return () => clearInterval(interval);
  }, []);
  
  useEffect(() => {
    if (usdtAmount && nasRatio && usdtRatio) {
      const calculatedNasAmount = (parseFloat(usdtAmount) * nasRatio) / usdtRatio;
      console.log("Calculated NAS Amount:", calculatedNasAmount);
      setNasAmount(calculatedNasAmount.toFixed());
    } else {
      setNasAmount('');
    }
  }, [usdtAmount, nasRatio, usdtRatio]);
  
  useEffect(() => {
    const previewLpTokens = async () => {
      if (nasRouterContract && nasAmount && usdtAmount) {
        try {
          const nasAmountWei = BigInt(parseUnits(nasAmount, 18));
          
          // NASPair에서 reserves 정보 가져오기
          const pairContract = new ethers.Contract(NAS_PAIR_ADDRESS, NASPairABI, getSigner());
          const [reserve0, reserve1] = await pairContract.getReserves();
          const nasReserve = reserve0;
          const usdtReserve = reserve1;
  
          // NASRouter의 quote 함수를 사용하여 예상 LP 토큰 수량 계산
          const expectedLiquidity = await nasRouterContract.quote(nasAmountWei, nasReserve, usdtReserve);
          setEstimatedLpTokens(formatUnits(expectedLiquidity, 18));
        } catch (error) {
          console.error('Error calculating liquidity:', error);
        }
      }
    };
    previewLpTokens();
  }, [nasRouterContract, nasAmount, usdtAmount, setEstimatedLpTokens]);

  const updateUserLiquidityInfo = async () => {
    if (!nasRouterContract || !nasFactoryContract || !signer) return;
  
    try {
      const userAddress = await signer.getAddress();
  
      // NASFactory에서 페어 주소 가져오기
      const pairAddress = await nasFactoryContract.getPair(NAS_TOKEN_ADDRESS, USDT_TOKEN_ADDRESS);
      const pairContract = new ethers.Contract(pairAddress, NASPairABI, signer);
  
      // 이벤트 로그 쿼리
      const filter = pairContract.filters.Transfer(null, userAddress);
      const logs = await pairContract.queryFilter(filter);
  
      const liquidityHistory = await Promise.all(logs.map(async (log) => {
        const block = await log.getBlock();
        return {
          amount: ethers.formatUnits(log.args.value, 18),
          timestamp: block.timestamp
        };
      }));
  
      setLiquidityHistory(liquidityHistory);
  
      // 락업 기간 계산
      if (liquidityHistory.length > 0) {
        const latestInfo = liquidityHistory[liquidityHistory.length - 1];
        const currentTime = Math.floor(Date.now() / 1000);
        const lockupPeriod = 30 * 24 * 60 * 60; // 30일
        const unlockTime = latestInfo.timestamp + lockupPeriod;
        const remainingTime = Math.max(0, unlockTime - currentTime);
        setLockRemainingTime(remainingTime);
      } else {
        setLockRemainingTime(0);
      }
  
    } catch (error) {
      console.error("유동성 정보 업데이트 중 오류 발생:", error);
      alert(`유동성 정보 업데이트 중 오류가 발생했습니다: ${error.message}`);
    }
  };

  const handleSupplyLiquidity = async () => {
    if (!signer || !nasRouterContract) {
      alert("지갑을 연결해주세요.");
      return;
    }
  
    if (isNaN(usdtAmount) || parseFloat(usdtAmount) <= 0) {
      alert('유효한 USDT 수량을 입력해주세요.');
      return;
    }
  
    try {
      const usdtAmountWei = ethers.parseUnits(usdtAmount, 6);
      const nasAmountWei = ethers.parseUnits(nasAmount, 18);
  
      console.log("USDT Amount (Wei):", usdtAmountWei.toString());
      console.log("NAS Amount (Wei):", nasAmountWei.toString());
      console.log("NAS Ratio:", nasRatio.toString());
      console.log("USDT Ratio:", usdtRatio.toString());
  
      // 비율 확인 (정확한 비교를 위해 스케일링 사용)
      const scaleFactor = BigInt(1e18);
      
      // USDT amount in 18 decimals
      const scaledUsdtAmount = BigInt(usdtAmountWei) * BigInt(1e12);
      
      console.log("Scaled USDT Amount:", scaledUsdtAmount.toString());
  
      const expectedNasAmount = (scaledUsdtAmount * BigInt(nasRatio)) / BigInt(usdtRatio);
  
      console.log("Expected NAS Amount:", expectedNasAmount.toString());
      console.log("Actual NAS Amount:", nasAmountWei.toString());
  
      if (expectedNasAmount !== nasAmountWei) {
        const difference = expectedNasAmount > nasAmountWei 
          ? expectedNasAmount - nasAmountWei 
          : nasAmountWei - expectedNasAmount;
        
        console.log("Difference:", difference.toString());
        
        const percentDifference = (difference * BigInt(10000) * scaleFactor) / expectedNasAmount;
        
        console.log("Percent Difference (scaled):", percentDifference.toString());
        
        const formattedPercentDifference = Number(percentDifference) / Number(scaleFactor) / 100;
        
        console.log("Formatted Percent Difference:", formattedPercentDifference + "%");
        
        if (percentDifference > BigInt(1e14)) { // 0.01% (1 basis point) 이상 차이나면 경고
          alert(`NAS와 USDT의 비율이 올바르지 않습니다. 차이: ${formattedPercentDifference.toFixed(6)}%`);
          return;
        }
      }
  
      // 최소 유동성 조건 확인
      const minLiquidityAmount = ethers.parseUnits("100", 6); // 예: 최소 100 USDT
      if (usdtAmountWei < minLiquidityAmount) {
        alert(`USDT 금액이 최소 유동성 금액(${ethers.formatUnits(minLiquidityAmount, 6)} USDT)보다 작습니다.`);
        return;
      }  

      // USDT와 NAS에 대한 승인 확인 및 요청
      const usdtContract = new ethers.Contract(USDT_TOKEN_ADDRESS, NASERC20ABI, signer);
      const nasContract = new ethers.Contract(NAS_TOKEN_ADDRESS, NASERC20ABI, signer);

      const usdtAllowance = await usdtContract.allowance(await signer.getAddress(), NAS_ROUTER_ADDRESS);
      const nasAllowance = await nasContract.allowance(await signer.getAddress(), NAS_ROUTER_ADDRESS);

      if (usdtAllowance < usdtAmountWei) {
        const usdtApproveTx = await usdtContract.approve(NAS_ROUTER_ADDRESS, ethers.MaxUint256);
        await usdtApproveTx.wait();
      }

      if (nasAllowance < nasAmountWei) {
        const nasApproveTx = await nasContract.approve(NAS_ROUTER_ADDRESS, ethers.MaxUint256);
        await nasApproveTx.wait();
      }

  
      const deadline = BigInt(Math.floor(Date.now() / 1000) + 60 * 20);
  
      const tx = await nasRouterContract.addLiquidity(
        NAS_TOKEN_ADDRESS,
        USDT_TOKEN_ADDRESS,
        nasAmountWei,
        usdtAmountWei,
        0, // amountAMin
        0, // amountBMin
        await signer.getAddress(),
        deadline,
        { gasLimit: 300000 } // 가스 리밋 설정
      );
  
      const receipt = await tx.wait();
      console.log("유동성 추가 완료, 트랜잭션 해시:", receipt.transactionHash);
      alert(`트랜잭션이 성공적으로 제출되었습니다: ${receipt.transactionHash}`);
  
      // 상태 업데이트
      await updateUserLiquidityInfo();
      setUsdtAmount('');
      setNasAmount('');
      
      // 풀 데이터 새로고침
      await initAndFetchContractData();
  
    } catch (error) {
      console.error('유동성 추가 중 오류 발생:', error);
      if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
        alert('거래 실행 중 오류가 발생했습니다. 가스 한도가 충분한지 확인해주세요.');
      } else if (error.code === 'INSUFFICIENT_FUNDS') {
        alert('잔액이 부족합니다. 충분한 NAS와 USDT를 보유하고 있는지 확인해주세요.');
      } else {
        alert(`유동성 추가에 실패했습니다: ${error.message}`);
      }
    }
  };

  const handleCalculateEarnings = async () => {
    if (!nasRouterContract || !nasFactoryContract || !nasAmount || !usdtAmount) {
      alert("지갑을 연결하고 NAS와 USDT 수량을 입력해주세요.");
      return;
    }
  
    try {
      const nasAmountWei = BigInt(parseUnits(nasAmount, 18));
      const usdtAmountWei = BigInt(parseUnits(usdtAmount, 6));
  
      // NASFactory를 통해 NASPair 주소 가져오기
      const pairAddress = await nasFactoryContract.getPair(NAS_TOKEN_ADDRESS, USDT_TOKEN_ADDRESS);
      const nasPairContract = new ethers.Contract(pairAddress, NASPairABI, signer);
  
      // 현재 풀의 reserve와 총 공급량 가져오기
      const reserves = await nasPairContract.getReserves();
      const totalSupply = await nasPairContract.totalSupply();
  
      let expectedLiquidity;
      if (totalSupply === 0n) {
        // 첫 유동성 공급의 경우
        expectedLiquidity = BigInt(Math.sqrt(Number(nasAmountWei * usdtAmountWei))) - 1000n;
      } else {
        // 기존 풀에 유동성 추가하는 경우
        const nasReserve = BigInt(reserves[0]);
        const usdtReserve = BigInt(reserves[1]);
        const nasLiquidity = (nasAmountWei * totalSupply) / nasReserve;
        const usdtLiquidity = (usdtAmountWei * totalSupply) / usdtReserve;
        expectedLiquidity = nasLiquidity < usdtLiquidity ? nasLiquidity : usdtLiquidity;
      }
  
      setEstimatedLpTokens(formatUnits(expectedLiquidity, 18));
  
      // NAS 가격 계산 (USDT 기준)
      const nasPrice = (usdtAmountWei * (10n ** 18n)) / nasAmountWei;
  
      // LP 토큰 가격 계산 (NAS 가격의 1/10)
      const lpTokenPrice = nasPrice / 10n;
  
      setPotentialEarnings(formatUnits(lpTokenPrice, 6)); // USDT 기준으로 6 decimals
  
      alert(`예상 Na(LP) 토큰 수량: ${formatUnits(expectedLiquidity, 18)}\n잠재적 수익: ${formatUnits(lpTokenPrice, 6)} USDT`);
  
    } catch (error) {
      console.error('LP 토큰 계산 중 오류 발생:', error);
      setEstimatedLpTokens("오류 발생");
      setPotentialEarnings("오류 발생");
      alert(`LP 토큰 계산 중 오류가 발생했습니다: ${error.message}`);
    }
  };
  
  return (
    <div className="aquarium">
      <section className="aqua1">
        <div className="background-image">
          <img className="aquarium-header" alt="Aquarium header" src={aquaHeaderImage} />
        </div>
        <div className="aqua1-content">
          <div className="liquidityPool">
            <div className="text-wrapper">Liquidity Pool 유동성풀</div>
          </div>
          <div className="aquarium-title">
            <h1>아쿠아리움</h1>
          </div>
          <div className="coin-bonus">
            <p className="na">
              아쿠아리움에서 살고있는<br /> 
              물고기들에게 먹이를 주고<br />
              Na. 코인 보너스를 받아보세요!
            </p>
          </div>
        </div>
      </section>

      <section className="aqua2">
        <div className="liquidity-box">
          <div className="token-input">
           <div className="token-info">
            <img src={usdtTokenImage} alt="USDT Token" />
            <div className="token-details">
              <span className="token-name">USDT</span>
              <span className="required">*필수입력</span>
              <span className="balance" title={`USDT 잔액: ${balances.usdtBalance}`}>USDT 잔액: {balances.usdtBalance}</span>
            </div>
           </div>
           <input
            type="text"
            value={usdtAmount}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9.]/g, '');
              if (value === '' || /^\d*\.?\d{0,6}$/.test(value)) {
                setUsdtAmount(value);
              }
            }}
            placeholder={`최소 ${minLiquidityAmount} USDT`}
            className="token-input-field"
           />
          </div>
          <div className="token-input">
           <div className="token-info">
            <img src={nasTokenImage} alt="NAS Token" className="nas-token-image" />
            <div className="token-details">
              <span className="token-name">NAS</span>
              <span className="required">*필수입력</span>
              <span className="balance" title={`NAS 잔액: ${balances.nasBalance}`}>NAS 잔액: {balances.nasBalance}</span>
              </div>
           </div>
           <input
             type="text"
             value={parseFloat(nasAmount).toFixed(18)}
             readOnly
             className="token-input-field"
           />
          </div>
          <p className="warning">*주의 : 30일 출금 제한이 적용됩니다.</p>
          <button onClick={handleSupplyLiquidity} className="supply-button">💰 유동성 공급하기</button>
          <button onClick={handleCalculateEarnings} className="preview-button">Na. 보너스 미리보기</button>
          {estimatedLpTokens !== '0' && <p>예상 Na(LP) 토큰 수량: {estimatedLpTokens}</p>}
        </div>
      </section>
      
      <section className="aqua3">
      <div className="info-box">
        <div className="info-box-header">
          <img src={checkImage} alt="Check" className="check-image" />
          <h3>유동성 채굴이란?</h3>
        </div>  
          <p>'Do! at NAS'의 유동성 채굴은 바다 깊은 곳에서<br />
          진귀한 보물을 찾는 모험과 같습니다.<br />
          예치 기간이 길어질수록, 여러분의 기여도는 더 커지고<br />
          그에 따라 더 많은 LP보상을 받게 됩니다.<br />
          더 긴 항해는 더 큰 보물과 이어집니다!⛴️<br />
           </p>
        </div>
        <div className="chart">
          <div className="chart-header">
          <img src={checkImage} alt="Check" className="check-image" />
          <h3>유동성풀 데이터</h3>
          </div>
          <p>총 유동성: {totalLiquidity ? formatNumber(parseFloat(totalLiquidity).toFixed(2)) : '0'} LP</p>
          <p>NAS 예치량: {reserves.nas ? formatNumber(parseFloat(reserves.nas).toFixed(2)) : '0'} NAS</p> 
          <p>USDT 예치량: {reserves.usdt ? formatNumber(parseFloat(reserves.usdt).toFixed(2)) : '0'} USDT</p>
          <Line data={chartData} />
        </div>
        <div className="liquidity-history">
          <h4>⎈ 나의 유동성풀 이력보기</h4>
          <div>
            <h5>▪︎ 유동성 추가 기록</h5>
            <ul>
              {liquidityHistory.added.map((event, index) => (
                <li key={index}>
                  NAS: {event.args && event.args.nasAmount ? formatUnits(BigInt(event.args.nasAmount.toString()), 18) : '0'}, 
                  USDT: {event.args && event.args.usdtAmount ? formatUnits(BigInt(event.args.usdtAmount.toString()), 6) : '0'}, 
                  받은 LP: {event.args && event.args.liquidity ? formatUnits(BigInt(event.args.liquidity.toString()), 18) : '0'}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h5>▪︎ 유동성 제거 기록</h5>
            <ul>
              {liquidityHistory.removed.map((event, index) => (
                <li key={index}>
                  NAS: {formatUnits(event.args.nasAmount, 18)}, USDT: {formatUnits(event.args.usdtAmount, 6)}, 받은 LP: {formatUnits(event.args.liquidity, 18)}
                </li>
              ))}
            </ul>
          </div>
         <div className="withdrawal-date">
            <h6>⎈ 출금 가능한 날짜</h6>
            <p>{new Date(Date.now() + lockRemainingTime * 1000).toLocaleDateString()}</p>
         </div> 
        </div>   
      </section>
      <Link to="/" className="main-link">홈 화면으로 돌아가기</Link>
    </div>
  );
});

export default Aquarium;